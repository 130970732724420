import React from "react"
import { getDistance } from "geolib"
import { Navigation } from "@styled-icons/boxicons-solid/Navigation"
import { navigate } from "gatsby"
import Iframe from 'react-iframe-click'

const TrackCard = ({
    name,
    type,
    gmaps_rating,
    gmaps_ratings_total,
    url,
    country,
    location,
    latLng,
    svg,
    horizontal = false,
}) => {
    return (
        <a
            href={url}
            className={`flex ${
                horizontal ? "flex-row" : "flex-col pt-6 pb-3 md:pb-0"
            }`}
            target={horizontal ? "_blank" : ""}
        >
            <div
                className={`shrink-0 rounded-xl relative ${
                    horizontal ? "w-1/2 h-32" : "w-full h-52 mb-2"
                } overflow-hidden bg-gray-100 border`}
                style={{
                    WebkitMaskImage: "-webkit-radial-gradient(white, black)",
                }}
            >
                {location && (
                    <Iframe
                        width="200%"
                        height="160%"
                        style={{
                            position: "absolute",
                            width: "200%",
                            height: "160%",
                            top: "-30%",
                            bottom: "-30%",
                            left: "-50%",
                            right: "-50%",
                            filter: "contrast(100%) brightness(120%)",
                        }}
                        frameBorder={0}
                        onInferredClick={() => {
                            if (horizontal) {
                                window.open(url, "_blank")
                            } else navigate(url)
                        }}
                        loading="lazy"
                        src={`https://www.google.com/maps/embed/v1/view?center=${
                            location[0]
                        },${location[1]}&maptype=satellite&zoom=${
                            type === "racetrack" ? "15" : "17"
                        }&key=${process.env.GATSBY_GMAPS_EMPED_API_KEY}`}
                    ></Iframe>
                )}
                <div className="absolute top-3 left-3 shadow uppercase text-xs font-semibold bg-white text-indigo-700 rounded-lg px-2 py-1 empty:w-20 empty:h-6">
                    {type}
                </div>
            </div>
            <div
                className={`flex ${
                    horizontal ? "flex-col pl-6 py-3" : "items-center h-10"
                }`}
            >
                <div className="font-racing uppercase text-xl empty:w-20 empty:h-5 empty:bg-gray-200 line-clamp-1 hover:line-clamp-none">
                    {name}
                </div>
                <div
                    className={`flex items-center text-base text-gray-500 space-x-1 ${
                        horizontal ? "" : "ml-2"
                    }`}
                >
                    <span
                        className={`stars-container stars-${
                            Math.round(gmaps_rating * 2) * 10
                        }`}
                    >
                        ★★★★★
                    </span>
                    <span>{gmaps_ratings_total}</span>
                </div>
                {latLng && (
                    <div
                        className={`flex items-center space-x-1 ${
                            horizontal ? "mt-auto" : "ml-auto"
                        }`}
                    >
                        <Navigation className="w-3" />
                        <span>
                            {Math.round(
                                getDistance(
                                    {
                                        latitude: location[0],
                                        longitude: location[1],
                                    },
                                    {
                                        latitude: latLng.lat,
                                        longitude: latLng.lng,
                                    }
                                ) / 1000
                            )}
                            km
                        </span>
                    </div>
                )}
            </div>
        </a>
    )
}

export default TrackCard
